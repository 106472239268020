import { Component, OnInit } from '@angular/core';
import { USER_APP_ROUTES, APP_NAME, AUTHENTICATION_ROUTES } from 'src/app/constants';
import { AuthService, SharedUtilsService, UserService } from 'src/app/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public APP_NAME = APP_NAME;
  public ROUTES = USER_APP_ROUTES;

  public userInfo: any = {};

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private sharedUtilsService: SharedUtilsService
    ) { }

  ngOnInit(): void {
    this.userInfo = this.userService.getInfoFromCache();
  }

  public logout() {
    const userType = this.userInfo.userType;
    this.authService.logout({userType})
      .subscribe(data => {
        this.userService.deleteUserInfo();
        const $this = this;
        setTimeout(function() {
          $this.sharedUtilsService.navigateTo(AUTHENTICATION_ROUTES.LOGIN.NAVIGATION);
        }, 100);
      }, error => { });
  }

}
